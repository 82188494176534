<!-- Filter -->
<ng-container *ngIf="isFilterable" [matColumnDef]="this.rowActionIcon">
  <div class="mx-auto my-4 flex w-full md:w-3/4">
    <label for="voice-search" class="sr-only">Search</label>
    <div class="relative w-full">
      <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <svg
          aria-hidden="true"
          class="h-5 w-5 text-gray-500 dark:text-gray-400"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
            clip-rule="evenodd"></path>
        </svg>
      </div>
      <input
        type="text"
        class="ic-input-search-table"
        (keyup)="applyFilter($event)"
        [placeholder]="placeholder"
        (keydown.enter)="onSubmitSearch()"
        [formControl]="searchField" />
    </div>
    <button type="submit" class="ic-button-search" [disabled]="!searchField.valid" (click)="onSubmitSearch()">
      <svg
        aria-hidden="true"
        class="-ml-1 mr-2 h-5 w-5"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
      </svg>
      <span class="hidden sm:inline"> Buscar </span>
    </button>
  </div>
</ng-container>

<div class="w-full overflow-auto">
  <!-- Table -->
  <table
    mat-table
    [dataSource]="tableDataSource"
    matSort
    (matSortChange)="announceSortChange($event)"
    class="w-full table-auto border-separate border-spacing-2 bg-ica-textsidebar">
    <!-- action column -->
    <ng-container *ngIf="rowActionIcon?.length" [matColumnDef]="rowActionIcon">
      <th mat-header-cell fxFlex *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" [id]="rowActionIcon" (click)="emitRowAction(element)">
        <button mat-button>
          <mat-icon>{{ rowActionIcon }}</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container *ngFor="let tableColumn of tableColumns" [matColumnDef]="tableColumn.dataKey">
      <!-- if sortable column header -->
      <ng-container *ngIf="tableColumn.isSortable; else notSortable">
        <th
          mat-header-cell
          fxFlex
          *matHeaderCellDef
          mat-sort-header
          [sortActionDescription]="'Sort by ' + tableColumn.dataKey"
          [arrowPosition]="tableColumn.position === 'right' ? 'before' : 'after'">
          {{ tableColumn.name }}
        </th>
      </ng-container>
      <!-- else not sortable -->
      <ng-template #notSortable>
        <th mat-header-cell fxFlex *matHeaderCellDef [class.text-right]="tableColumn.position === 'right'">
          {{ tableColumn.name }}
        </th>
      </ng-template>

      <!-- column data -->
      <ng-container *ngIf="tableColumn.typeElement === 'actions'; else othersConditions">
        <td mat-cell *matCellDef="let element" [class.text-right]="tableColumn.position === 'right'">
          <div class="flex flex-wrap items-center justify-center gap-1">
            <ng-container *ngFor="let item of tableColumn.actions">
              <ng-container
                *ngIf="
                  conditions &&
                    !conditionRow(element, item.indexCondition ?? item.multiIndexCondition ?? 0) &&
                    !item.notCondition;
                  else actionNormal
                ">
              </ng-container>

              <ng-template #actionNormal>
                <button
                  class="ic-action-edit"
                  *ngIf="item.action === 'edit'"
                  (click)="emitEventEdit(element)"
                  [matTooltip]="item.tooltip">
                  <mat-icon class="">{{ item.icon ?? 'keyboard_arrow_up' }}</mat-icon>
                </button>

                <button
                  class="ic-action-delete"
                  *ngIf="item.action === 'delete'"
                  (click)="emitEventDelete(element)"
                  [matTooltip]="item.tooltip">
                  <mat-icon class="">{{ item.icon ?? 'delete_forever' }}</mat-icon>
                </button>

                <button
                  class="ic-action-third"
                  *ngIf="item.action === 'refund'"
                  (click)="emitEventRefund(element)"
                  [matTooltip]="item.tooltip">
                  <mat-icon class="">money_off</mat-icon>
                </button>

                <button
                  class="ic-action-down"
                  *ngIf="item.action === 'down'"
                  (click)="emitEventDown(element)"
                  [matTooltip]="item.tooltip">
                  <mat-icon>keyboard_arrow_down</mat-icon>
                </button>

                <button
                  class="ic-action-edit"
                  *ngIf="item.action === 'active'"
                  (click)="emitEventActive(element)"
                  [matTooltip]="item.tooltip">
                  <mat-icon>keyboard_arrow_up</mat-icon>
                </button>

                <button
                  class="ic-action-third"
                  *ngIf="item.action === 'print'"
                  (click)="emitEventPrint(element)"
                  [matTooltip]="item.tooltip">
                  <mat-icon>print</mat-icon>
                </button>

                <button
                  class="ic-action-custom"
                  [ngClass]="item.customColor ? item.customColor : 'bg-ica-third'"
                  *ngIf="item.action === 'custom'"
                  (click)="emitEventCustom(element, item.valueEvent)"
                  [matTooltip]="item.tooltip">
                  <mat-icon>{{ item.icon }}</mat-icon>
                </button>
              </ng-template>
            </ng-container>
          </div>
        </td>
      </ng-container>

      <ng-template #othersConditions>
        <td
          mat-cell
          *matCellDef="let element"
          [class.text-right]="tableColumn.position === 'right'"
          (click)="emitGoToDetails(element)">
          <div [ngSwitch]="tableColumn.typeElement">
            <div *ngSwitchCase="'image'">
              <img class="w-28 p-4 md:w-32 lg:w-48" [src]="element | dataPropertyGetter : tableColumn.dataKey" />
            </div>

            <div *ngSwitchDefault>
              {{
                tableColumn.pipe === 'money'
                  ? (element | dataPropertyGetter : tableColumn.dataKey | currency : 'MXN' : 'symbol' : '1.2-2')
                  : tableColumn.pipe === 'datetime'
                  ? (element | dataPropertyGetter : tableColumn.dataKey | date : 'yyyy-MM-dd hh:mm a' : 'es')
                  : tableColumn.pipe === 'date'
                  ? (element | dataPropertyGetter : tableColumn.dataKey | formatDateCustom : 'yyyy-MM-dd hh:mm a')
                  : tableColumn.pipe === 'phone'
                  ? (element | dataPropertyGetter : tableColumn.dataKey | mask : '(000) 000-0000')
                  : (element | dataPropertyGetter : tableColumn.dataKey)
              }}
            </div>
          </div>
        </td>
      </ng-template>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr [ngClass]="color_td" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <ng-container *ngIf="tableDataSource.filteredData.length === 0">
    <div class="flex justify-center">
      <span class="ic-label-primary">{{ notRecord }}</span>
    </div>
  </ng-container>

  <!-- Pagination -->
  <mat-paginator
    *ngIf="isPageable"
    [pageSizeOptions]="paginationSizes"
    [pageSize]="defaultPageSize"
    showFirstLastButtons
    itemsPerPageLabel="">
  </mat-paginator>
</div>
