import { NgModule } from '@angular/core';
//import { InputTextModule } from 'primeng/inputtext';
//import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
//import { InputMaskModule } from 'primeng/inputmask';
//import { SidebarModule } from 'primeng/sidebar';
//import { AvatarGroupModule } from 'primeng/avatargroup';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SplitButtonModule } from 'primeng/splitbutton';
import { InputNumberModule } from 'primeng/inputnumber';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { FileUploadModule } from 'primeng/fileupload';
import { CarouselModule } from 'primeng/carousel';
import { TabViewModule } from 'primeng/tabview';
import { DividerModule } from 'primeng/divider';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ImageModule } from 'primeng/image';
import { GalleriaModule } from 'primeng/galleria';
import { AccordionModule } from 'primeng/accordion';
import { TagModule } from 'primeng/tag';

@NgModule({
  declarations: [],
  exports: [
    //InputTextModule,
    //ButtonModule,
    CalendarModule,
    //InputMaskModule,
    //SidebarModule,
    //AvatarGroupModule,
    TableModule,
    DialogModule,
    InputSwitchModule,
    SplitButtonModule,
    InputNumberModule,
    ScrollPanelModule,
    FileUploadModule,
    CarouselModule,
    TabViewModule,
    AutoCompleteModule,
    DividerModule,
    ImageModule,
    GalleriaModule,
    AccordionModule,
    TagModule,
  ],
})
export class PrimeNGModule {}
